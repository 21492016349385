import React, { Component, useRef, useState } from "react";
import { Row, Col, Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import axios from "axios";
import { URL_PreScheduling } from "../Services/preSchedulingService";
import { useNavigate } from "react-router-dom";
import { ptBR } from "date-fns/locale";
import { FaChevronRight, FaSpinner } from "react-icons/fa";
import moment from "moment";
import { FaChevronLeft } from "react-icons/fa";
import { addDays, subDays, parseISO, format } from "date-fns";
import { Spinner } from "../../common/components/spinner/spinner";
import { TbCalendarTime } from "react-icons/tb";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { useEffect } from "react";
const styles = {
  div63047802: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    marginBottom: "24px"
  },
  button65457367: {
    display: "flex",
    minHeight: "40px",
    maxHeight: "40px",
    width: "40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    border: "1px",
    paddingTop: "9px",
    paddingRight: "16px",
    paddingBottom: "9px",
    paddingLeft: "16px",
    backgroundColor: "transparent"
  },
  faChevronLeft72427346: {
    color: "171821",
    width: "14px",
    height: "14px"
  },
  div73797786: {
    fontFamily: "inter,sans-serif",
    color: "#171821",
    flex: "1 0 0",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal"
  },
  h686398873: {
    fontFamily: "inter,sans-serif",
    color: "red"
  },
  div1197425547: {
    gap: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    alignContent: "flex-start",
    alignSelf: "stretch",
    flexWrap: "wrap"
  },
  div1227825524: {
    display: "flex",
    minWidth: "340px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "4px",
    border: "1px solid var(--Color-Gray-100, #E3E3E3"
  },
  div1266125508: {
    height: "100",
    width: "100%",
    margin: "0px",
    padding: "0px"
  },
  div1285114061: {
    width: "100%",
    height: "108px",
    alignItems: "center",
    textAlign: "center"
  },
  img1310813701: {
    width: "100px",
    height: "108px",
    objectFit: "cover",
    // Ensures the image covers the area without distortion
    objectPosition: "center",
    // Centers the image within the box
    paddingTop: "8px"
  },
  img1374014023: {
    paddingTop: "8px"
  },
  div1407515221: {
    width: "100%",
    height: "60px",
    radius: "7.35px",
    textAlign: "center",
    margin: "0px"
  },
  h61436515201: {
    fontFamily: "Inter, sans-serif",
    // Font family
    fontSize: "16px",
    // Font size
    fontWeight: 600,
    // Font weight
    lineHeight: "19.36px",
    // Line height
    textAlign: "center",
    // Centers text within the h6 itself
    padding: "0px",
    margin: "0px",
    borderBottom: "1px solid var(--Color-Gray-100, #E3E3E3)",
    paddingTop: "16px",
    paddingRight: "8px",
    paddingLeft: "8px",
    paddingBottom: "16px",
    gap: "8px",
    height: "50px",
    width: "100%"
  },
  div1556821768: {
    display: "flex",
    // Flexbox for horizontal alignment
    alignItems: "center",
    // Vertically center the children
    width: "100%",
    height: "50px",
    paddingBottom: "8px",
    textAlign: "center",
    margin: "0px",
    borderBottom: "1px solid var(--Color-Gray-100, #E3E3E3)"
  },
  button1627117877: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "55px",
    height: "55px",
    backgroundColor: "transparent",
    // Ghost button, no background
    border: "1px",
    color: "#171821",
    borderRadius: "4px",
    opacity: 1,
    // Assuming opacity 0px in the design is a typo
    cursor: "pointer",
    radius: "6px",
    gap: "8px",
    paddingTop: "9px",
    paddingRight: "16px",
    paddingBottom: "9px",
    paddingLeft: "16px",
    marginTop: "5px"
  },
  div1794220105: {
    width: "100%",
    height: "50%",
    marginBottom: "2px",
    justifyContent: "space-between"
  },
  span1851919353: {
    overflow: "hidden",
    color: "var(--Color-Primary-500, #171821)",
    textAlign: "center",
    fontFamily: "Inter, sans-serif",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    paddingBottom: "3px"
  },
  tbCalendarTime1939019619: {
    paddingBottom: "3px"
  },
  button2013321736: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "55px",
    height: "55px",
    backgroundColor: "transparent",
    // Ghost button, no background
    border: "1px",
    // Dark Blue color for border
    color: "#171821",
    // Dark Blue color for icon
    borderRadius: "4px",
    opacity: 1,
    // Assuming opacity 0px in the design is a typo
    cursor: "pointer",
    radius: "6px",
    gap: "8px",
    paddingTop: "9px",
    paddingRight: "16px",
    paddingBottom: "9px",
    paddingLeft: "16px",
    marginTop: "5px"
  },
  row2179425340: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "8px"
  },
  button2271824598: {
    fontFamily: "Inter, sans-serif",
    backgroundColor: "#171821",
    // Ensure the Badge inherits the same background
    border: "none",
    padding: "11px 12px",
    borderRadius: "6px",
    gap: "8px",
    minHeight: "40px",
    maxHeight: "40px" // Remove any border around the Badge
  },
  div2350524553: {
    color: "var(--Color-White-Pure, #FFF)",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    padding: "8,8,0,0"
  },
  h62484325242: {
    fontFamily: "inter,sans-serif",
    fontSize: "14px"
  }
};
export default class Professionals extends Component {
  constructor(props) {
    super(props);
    const myParam = window.location.pathname.split("/")[1];
    this.state = {
      listProvider: [],
      myParam: myParam,
      formDay: [],
      listDaysFunctionality: [],
      listScheduling: [],
      dateSelected: new Date(),
      loading: false,
      professionalLoading: true
    };
  }
  componentDidMount = async () => {
    if (localStorage.getItem("listProcedureId") == null) {
      this.toggle();
    } else {
      await this.consultProviders();
      await this.searchAppointmentList();
    }
  };
  consultProviders = async () => {
    const {
      myParam
    } = this.state;
    if (myParam != "") {
      await axios.get(`${URL_PreScheduling}/GetProviders`, {
        params: {
          aliasClinic: myParam,
          listIdProcedure: localStorage.getItem("listProcedureId")
        }
      }).then(response => {
        const {
          data
        } = response;
        if (data != "Clínica não localizada!") this.arrayProviders = data;
        this.setState({
          listProvider: data
        });
      }).catch(() => {
        this.setState({
          professionalLoading: false
        });
      });
      this.setState({
        professionalLoading: false
      });
    }
  };
  searchAppointmentList = async () => {
    let listIdCompanyProvider = [];
    this.arrayProviders.forEach(element => listIdCompanyProvider.push(element.idCompanyProvider));
    let listProcedures = JSON.parse(localStorage.getItem("listProcedures"));
    let servicesDuration = 0;
    if (listProcedures != null && listProcedures.length > 0) {
      listProcedures.forEach(element => {
        servicesDuration += element.duration;
      });
    }
    const {
      myParam
    } = this.state;
    let listScheduling = [];
    await axios.get(`${URL_PreScheduling}/GetSchedulingDay/${myParam}/${servicesDuration}`, {
      params: {
        listProvidersKey: JSON.stringify(listIdCompanyProvider),
        date: format(new Date(), "yyyy-MM-dd HH:mm")
      }
    }).then(resp => {
      const {
        data
      } = resp;
      if (data.length > 0) {
        if (data) {
          data.forEach(element => {
            let map = {
              hourList: element.hourList,
              idCompanyProvider: element.idCompanyProvider,
              day: new Date()
            };
            listScheduling.push(map);
          });
          console.log(listScheduling, 'antes');
          for (let schedule of listScheduling) schedule.hourList.sort((a, b) => moment(a).diff(moment(b)));
          this.setState({
            formDay: listScheduling
          });
        }
      }
    });
  };
  searchAppointmentProvider = async (date, id) => {
    let listIdCompanyProvider = [];
    listIdCompanyProvider.push(id);
    let listProcedures = JSON.parse(localStorage.getItem("listProcedures"));
    let servicesDuration = 0;
    if (listProcedures != null && listProcedures.length > 0) {
      listProcedures.forEach(element => {
        servicesDuration += element.duration;
      });
    }
    const {
      myParam,
      formDay
    } = this.state;
    await axios.get(`${URL_PreScheduling}/GetSchedulingDay/${myParam}/${servicesDuration}`, {
      params: {
        listProvidersKey: JSON.stringify(listIdCompanyProvider),
        date: date == undefined ? format(new Date(), "yyyy-MM-dd HH:mm") : format(new Date(date), "yyyy-MM-dd HH:mm")
      }
    }).then(resp => {
      const {
        data
      } = resp;
      if (data.length > 0) {
        if (data) {
          formDay.forEach(e => {
            if (e.idCompanyProvider == data[0].idCompanyProvider) {
              e.hourList = data[0].hourList;
              e.idCompanyProvider = data[0].idCompanyProvider;
              e.day = date == undefined ? new Date() : date;
            }
          });
          for (let schedule of formDay) schedule.hourList.sort((a, b) => moment(a).diff(moment(b)));
          this.setState({
            formDay
          });
        }
      }
    });
  };
  validate = () => {
    const {
      model
    } = this.state;
    const errors = {};
    let isError = 0;
    if (!model.idDoctor) {
      isError++;
      errors.idDoctorError = true;
    } else errors.idDoctorError = false;
    this.setState({
      errors: errors
    });
    return isError;
  };
  setValues = async (e, field) => {
    const {
      model
    } = this.state;
    if (field == "idDoctor") {
      model[field] = e;
    } else model[field] = e.target.value;
    this.setState({
      model
    });
    this.validate();
  };
  onSelect = async (d, idCompanyProvider) => {
    this.setState({
      loading: true,
      date: d
    });
    await this.searchAppointmentProvider(d, idCompanyProvider);
    this.setState({
      loading: false
    });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  render() {
    const {
      listProvider,
      myParam,
      loading,
      professionalLoading,
      formDay,
      date,
      idCompanyProviderSelected
    } = this.state;
    return <div>
        <div style={styles.div63047802}>
          <button style={styles.button65457367} onClick={() => window.history.back()} // Replace with your actual event handler
        >
            <FaChevronLeft style={styles.faChevronLeft72427346} />
          </button>
          <div className="text-left" style={styles.div73797786}>
            Selecione a data e horário desejado
          </div>
        </div>
        {this.state.modal == false || this.state.modal == undefined ? <div>
            {listProvider.length > 0 ? <div>
                <Cards myParam={myParam} listProvider={listProvider} idCompanyProviderSelected={idCompanyProviderSelected} loading={loading} formDay={formDay} onSelect={this.onSelect} date={date} />
              </div> : <div>
                {professionalLoading && <div className="text-center">
                    <FaSpinner className="icon_pulse text-center" />
                  </div>}
                {!professionalLoading && <h6 className="text-center mt-4" style={styles.h686398873}>
                    Indisponível, agende separadamente!
                  </h6>}
              </div>}{" "}
          </div> : <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop="static">
            <ModalHeader>Selecione um serviço!</ModalHeader>
            <ModalBody>
              É obrigatório selecionar um serviço, você será direcionado para a
              tela de serviços.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" href={`/${myParam}`}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>}
      </div>;
  }
}
function Cards(props) {
  const navigate = useNavigate();
  const redirectScheduling = (h, l, p) => {
    localStorage.setItem("providerSelected", JSON.stringify({
      nameProvider: p.nameCompanyProvider,
      idCompanyProvider: l.idCompanyProvider
    }));
    localStorage.setItem("dateTimeSelected", h);
    navigate("/" + props.myParam + "/scheduling", {
      replace: false
    });
  };
  const selectedDate = async (data, id) => {
    await props.onSelect(data, id);
  };
  const delay = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  const handlePrevDay = async (l, index) => {
    setProfessionalSelected(l.idCompanyProvider);
    const prevDate = subDays(l.day, 1);
    updateDayForProvider(prevDate, index);
    await selectedDate(l.day, l.idCompanyProvider);
    setProfessionalSelected(null);
  };
  const handleNextDay = async (l, index) => {
    setProfessionalSelected(l.idCompanyProvider);
    const nextDate = addDays(l.day, 1);
    updateDayForProvider(nextDate, index);
    await selectedDate(l.day, l.idCompanyProvider);
    setProfessionalSelected(null);
  };
  const handleSelectedDay = async (e, l, index) => {
    setShowCalendar(!showCalendar);
    setProfessionalSelected(l.idCompanyProvider);
    updateDayForProvider(e, index);
    await selectedDate(l.day, l.idCompanyProvider);
    setProfessionalSelected(null);
    toggleCalendar();
  };
  const updateDayForProvider = (newDate, index) => {
    try {
      const updatedFormDay = [...props.formDay];
      updatedFormDay[index].day = newDate;
      setCurrentDate(newDate);
    } catch (error) {
      console.error("Error in updateDayForProvider:", error);
    }
  };
  const [professionalSelected, setProfessionalSelected] = useState(null);
  const limitDays = localStorage.getItem("LimitPreScheduling");
  const newDateLimit = addDays(new Date(), limitDays);
  const [showCalendar, setShowCalendar] = useState(false);
  const [idCalendar, setIdCalendar] = useState(0);
  const toggleCalendar = id => {
    setShowCalendar(!showCalendar);
    setIdCalendar(id);
  };
  const closeCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  return <div style={styles.div1197425547}>
      {props.listProvider.map((p, index) => <div key={p.id} style={styles.div1227825524}>
          <div style={styles.div1266125508}>
            <div style={styles.div1285114061}>
              {p.logo != null ? <img className="mb-2 img-fluid rounded-circle thumb64" width={100} height={108} src={"data:image/png;base64," + p.logo} alt="Contact" style={styles.img1310813701} /> : <img width={100} height={108} className="mb-2 img-fluid rounded-circle thumb64" src="/Image/sem_foto.png" alt="Contact" style={styles.img1374014023} />}
            </div>
            <div className="media-body pt-1" style={styles.div1407515221}>
              <h6 style={styles.h61436515201}>
                {p.nameCompanyProvider}
              </h6>
            </div>
            <div>
              {p.idCompanyProvider == professionalSelected && <Spinner />}
              <div>
                {props.formDay.length > 0 ? props.formDay.map((l, index) => l.idCompanyProvider == p.idCompanyProvider ? <div key={index}>
                        <div style={styles.div1556821768}>
                          {currentDate > new Date() ? <button style={styles.button1627117877} onClick={() => handlePrevDay(l, index)}>
                              <FaChevronLeft style={styles.faChevronLeft72427346} />
                            </button> : null}
                          <div
                //  onClick={e=>toggleCalendar(p.id)}
                key={p.id} className="text-center" style={styles.div1794220105}>
                            <div>
                              {" "}
                              <span style={styles.span1851919353} onClick={e => toggleCalendar(p.id)}>
                                {format(l.day, "PPPP", {
                        locale: ptBR
                      })}
                              </span>{" "}
                              <TbCalendarTime style={styles.tbCalendarTime1939019619} size={24} onClick={e => toggleCalendar(p.id)} />
                              {showCalendar && idCalendar == p.id && <CalendarComponent index={index} l={l} handleSelectedDay={handleSelectedDay} onClose={closeCalendar} />}
                            </div>
                          </div>
                          <button style={styles.button2013321736} onClick={() => handleNextDay(l, index)}>
                            <FaChevronRight style={styles.faChevronLeft72427346} />
                          </button>
                        </div>
                        <Row className="text-center row-cols-lg-auto g-2 align-items-center" style={styles.row2179425340}>
                          {l.hourList.length > 0 && new Date() <= parseISO(l.hourList.slice(-1)[0]) && parseISO(l.hourList.slice(-1)[0]) <= newDateLimit ? l.hourList.map((h, index) => parseISO(h) > new Date() ? <Col key={index}>
                                  <Button color="dark" style={styles.button2271824598}>
                                    <div style={styles.div2350524553} onClick={e => redirectScheduling(h, l, p)}>
                                      {format(parseISO(h, new Date()), "HH:mm")}
                                    </div>
                                  </Button>{" "}
                                </Col> : null) : <div className="text-center mt-3">
                              <h6 className="text-center text-danger" style={styles.h62484325242}>
                                Horários indisponíveis!
                              </h6>
                            </div>}
                        </Row>
                      </div> : null) : null}
              </div>
            </div>
          </div>
        </div>)}
    </div>;
}
const CalendarComponent = props => {
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        console.log(event.target);
        props.onClose(); // Fecha o calendário
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.onClose]);
  return <div ref={calendarRef}>
      <ReactDatePicker onSelect={e => props.handleSelectedDay(e, props.l, props.index)} selected={selectedDate} onChange={date => setSelectedDate(date)} closeOnScroll={true} locale={pt} showPopperArrow={false} inline peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
    </div>;
};