import React, { Component } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { Button, Input } from "reactstrap";
import InputMask from "react-input-mask";
import CharacterRemover from "character-remover";
const styles = {
  div6732062: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    alignSelf: "stretch",
    paddingBottom: "16px"
  },
  button8951675: {
    display: "flex",
    minHeight: "40px",
    maxHeight: "40px",
    width: "40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    border: "1px",
    paddingTop: "9px",
    paddingRight: "16px",
    paddingBottom: "9px",
    paddingLeft: "16px",
    backgroundColor: "transparent"
  },
  faChevronLeft15501654: {
    color: "171821",
    width: "14px",
    height: "14px"
  },
  span16872046: {
    fontFamily: "inter,sans-serif",
    color: "var(--Color-Primary-500, #171821)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal"
  },
  div20742837: {
    display: "flex",
    paddingRight: "6px",
    paddingLeft: "6px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    justifyContent: "center",
    paddingBottom: "24px"
  },
  a24032821: {
    textAlign: "center",
    fontFamily: "inter,sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal"
  },
  div28494381: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    alignSelf: "stretch"
  },
  div30743813: {
    position: "relative",
    width: "100%"
  },
  bsTelephone31413430: {
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#aaa"
  },
  input34443795: {
    paddingLeft: "32px"
  }
};
export class InsertPhonePage extends Component {
  state = {
    model: {}
  };
  setValues = e => {
    const {
      model
    } = this.state;
    const input = e.target;
    model[input.name] = input.value;
    this.setState({
      model
    });
  };
  render() {
    const myParam = window.location.pathname.split("/")[1];
    const {
      model
    } = this.state;
    return <div>
        <div style={styles.div6732062}>
          <button style={styles.button8951675} onClick={() => window.history.back()}>
            <FaChevronLeft style={styles.faChevronLeft15501654} />
          </button>
          <span style={styles.span16872046}>
            Consulte seus agendamentos
          </span>
        </div>

        <div style={styles.div20742837}>
          <a style={styles.a24032821}>
            Por favor, insira seu número de telefone para que possamos ajudá-lo
            a visualizar seus agendamentos.
          </a>
        </div>

        <div style={styles.div28494381}>
          <div style={styles.div30743813}>
            <BsTelephone style={styles.bsTelephone31413430} />
            <Input type="text" name="phone" placeholder="Telefone" value={model.phone} onChange={e => this.setValues(e)} mask="(99) 9 9999-9999" tag={InputMask} style={styles.input34443795} />
          </div>
          <Button disabled={!model.phone} href={"/" + myParam + "/schedulingssearch/" + CharacterRemover.removeAll(model.phone)} block color="dark" style={{
          width: "100%",
          fontWeight: "600",
          opacity: !model.phone ? 0.25 : null,
          cursor: !model.phone ? "not-allowed" : "pointer"
        }}>
            Confirmar
          </Button>
        </div>
      </div>;
  }
}