

import "bootstrap/dist/css/bootstrap.min.css";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { Component } from "react";
import "./App.css";
import { history } from "./history";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export default class App extends Component {
  onServiceWorkerUpdate = (registration) => {
    if (registration && registration.waiting) this.refreshAction(registration);
  };

  updateServiceWorker = (registration) => {
    console.log("Checking for service worker update...");
    const waitingWorker = registration && registration.waiting;

    if (waitingWorker) {
      console.log("Service worker update found!");
      waitingWorker.addEventListener("statechange", (event) => {
        console.log("State changed:", event.target.state);
        if (event.target.state === "activated") {
          console.log("New service worker activated!");
          this.forceUpdate();
          window.location.reload();
          // window.location.href = `${process.env.REACT_APP_URL}${window.location.pathname}`;
          
        }
      });
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
    } else {
      console.log("No update available.");
    }
  };

  componentDidMount = async () => {
    if (
      process.env.REACT_APP_NODE_ENV === "production" ||
      process.env.REACT_APP_NODE_ENV === "staging"
    ) {
      serviceWorkerRegistration.register({
        onUpdate: this.onServiceWorkerUpdate,
      });
    }

    
      // serviceWorkerRegistration.register({
      //   onUpdate: this.onServiceWorkerUpdate,
      // });
    

    // Listen for route changes and trigger update check
    history.listen(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (registration) {
            registration.update();
          }
        });
      }
    });
  };

  refreshAction = (registration) => {
    localStorage.clear();
    sessionStorage.clear();
    this.updateServiceWorker(registration);
    console.log("teste refreshAction");
  };

  render() {
    return (
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    );
  }
}