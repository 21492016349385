import React, { Component } from "react";
import Header from "./Header";
import axios from "axios";
import { URL_PreScheduling } from "../Services/preSchedulingService";
const styles = {
  div23023216: {
    maxWidth: "100%",
    paddingTop: "8px",
    paddingRight: "8px",
    paddingBottom: "32px",
    paddingLeft: "8px",
    alignItems: "center",
    gap: "24px",
    justifyContent: "center"
  },
  section26533198: {
    maxWidth: '100%',
    paddingTop: "8px",
    //paddingRight: "4px",
    paddingBottom: "32px",
    //paddingLeft: "4px",
    alignItems: "center",
    gap: "24px",
    alignSelf: "stretch",
    justifyContent: "center",
    margin: "auto"
  }
};
class Base extends Component {
  constructor(props) {
    super(props);
    const myParam = window.location.pathname.split("/")[1];
    this.state = {
      myParam: myParam,
      modelClinic: {},
      establishmentLoaded: false
    };
  }
  componentDidMount = async () => {
    await this.consultClinic();
  };
  consultClinic = async () => {
    const {
      myParam
    } = this.state;
    if (myParam != "") {
      await axios.get(`${URL_PreScheduling}/GetClinicById/${myParam}`).then(resp => {
        const {
          data
        } = resp;
        if (data == "Clínica não localizada!") throw new Error("I crashed!");else if (data == "Empresa desativada.") {} else {
          localStorage.setItem('fields', JSON.stringify({
            showCpf: data.showCpfInLink,
            cpfRequired: data.cpfRequired,
            showBirthDate: data.showBirthDateInLink,
            birthDateRequired: data.birthDateRequired,
            showEmail: data.showEmailInLink,
            emailRequired: data.emailRequired
          }));
          localStorage.setItem("PaymentRequired", data.paymentRequired);
          // localStorage.setItem("CpfRequired", data.cpfRequired);
          localStorage.setItem("PublicTokenGalax", data.publicTokenGalax);
          localStorage.setItem("TypeOfRedirect", data.typeOfRedirectInWebScheduling);
          localStorage.setItem("LimitPreScheduling", data.limitPreScheduling ?? 360);
          localStorage.setItem("dataClinic", JSON.stringify(data));
          this.setState({
            modelClinic: data,
            establishmentLoaded: true
          });
        }
      }).catch(() => {
        throw new Error();
      });
    } else this.setState({
      establishmentLoaded: false
    });
  };
  render() {
    const {
      modelClinic,
      establishmentLoaded
    } = this.state;
    return <div>
        <Header modelClinic={modelClinic} establishmentLoaded={establishmentLoaded} />
        {establishmentLoaded ? <div style={styles.div23023216}>
            <section className="section-container" style={styles.section26533198}>
              {this.props.children}
            </section>
          </div> : null}
      </div>;
  }
}
export default Base;