import React, { Component } from "react";
import IGICoficialazul from "./Image/IGICoficialazul.png";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const styles = {
  faSpinner810891: {
    color: 'rgb(1, 9, 12)'
  },
  div12782273: {
    width: '100%',
    //width: '99%',
    position: 'relative',
    height: '130px'
  },
  img16812261: {
    position: 'absolute',
    top: '25px',
    left: 'calc(50% - 50px)',
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderStyle: 'solid',
    borderColor: "#F0F0F0"
  }
};
export default class Header extends Component {
  constructor(props) {
    super(props);
    const myParam = window.location.pathname.split("/")[1];
    this.state = {
      loadingClinic: true,
      myParam: myParam,
      establishmentLoaded: true
    };
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  render() {
    const {
      myParam
    } = this.state;
    const {
      establishmentLoaded,
      modelClinic
    } = this.props;
    return <div>
        {establishmentLoaded == false ? <div className="text-center mt-5">
            <FaSpinner size={45} style={styles.faSpinner810891} className="icon_pulse" />
          </div> : <div>
            <BodyHeader modelClinic={modelClinic} myParam={myParam} barColor={modelClinic.barColor ?? '#000'} />
          </div>}
      </div>;
  }
}
function BodyHeader(props) {
  const navigate = useNavigate();
  const navigationHome = () => {
    navigate("/" + props.myParam);
  };
  return <div style={styles.div12782273}>
      <div style={{
      position: 'absolute',
      width: '100%',
      top: '0px',
      right: '0px',
      left: '0px',
      backgroundColor: props.barColor,
      borderBottom: '1px solid #f0f0f0',
      boxSizing: 'border-box',
      height: '75px'
    }} />
      <img onClick={e => navigationHome()} className="border border-light" style={styles.img16812261} src={props.modelClinic.file != undefined ? "data:imageFile/png;base64," + props.modelClinic.file : IGICoficialazul} />
    </div>;
}